@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}
